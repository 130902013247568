export function useViewArg<T>(name: string) {
    // Return a property from the view-args payload.

    const value = JSON.parse(
        document.getElementById('view-args')!.textContent!,
    );

    return value[name] as T;
}

export function useFormErrors() {
    const value = JSON.parse(
        document.getElementById('form-errors')!.textContent!,
    );

    return value;
}
